import React, { Component } from "react"
import styled, { withTheme } from "styled-components"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Recaptcha from "react-google-invisible-recaptcha"

import FullPage from "../components/fullpage"
import Content from "../components/content"
import Title from "../components/title"

import { Container, Row, Col, Form, Button } from "react-bootstrap"

import { addMessage } from "../actions/contactAction"
import { getTranslate, Translate } from "react-localize-redux"
import { loc } from "../localization"

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      messageSent: false,
      namePlaceholder: loc("contact.messageSuccessful")
    }
    // console.log({x:<Translate id="contact.title" />}) 
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  sendMessage = () => {
    this.recaptcha.execute()
  }

  onResolved = () => {
    const { name, email, message } = this.state
    this.props
      .addMessage({
        name,
        email,
        message,
      })
      .then(() => {
        this.setState({ messageSent: true, name: "", email: "", message: "" })
      })
  }

  render() {
    const theme = this.props.theme
    var { name, email, message, messageSent } = this.state;

    return (
      <FullPage id="contact" className="landing-text" backgroundColor={theme.md}>
        <Content verticalAlign="middle">
          <Container style={{ paddingBottom: "60px" }}>
            <Row>
              <Col>
                <Title color={theme.ml}>
                  <Translate id="contact.title" />
                </Title>
              </Col>
            </Row>
            {messageSent && (
              <div className="alert alert-success fade show">
                <strong><Translate id="contact.success" /></strong>
                <Translate id="contact.messageSuccessful" />
              </div>
            )}
            <Row style={{ justifyContent: "center" }}>
              <Col>
                <Form>
                  <Form.Group controlId="name">
                    <StyledLabel for="name">
                      <Translate id="contact.name" />
                    </StyledLabel>
                    <StyledControl
                      name="name"
                      type="text"
                      size="lg"
                      onChange={e => this.handleChange(e)}
                      value={name}
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <StyledLabel for="email">
                        <Translate id="contact.email" />
                      </StyledLabel>
                    <StyledControl
                      name="email"
                      type="email"
                      size="lg"
                      onChange={e => this.handleChange(e)}
                      value={email}
                    />
                  </Form.Group>
                  <Form.Group controlId="message">
                    <StyledLabel for="message">
                      <Translate id="contact.urMessage" />
                    </StyledLabel>
                    <StyledControl
                      name="message"
                      as="textarea"
                      style={{ padding: "0px 15px" }}
                      rows="6"
                      maxlength="500"
                      onChange={e => this.handleChange(e)}
                      value={message}
                    />
                  </Form.Group>
                  <StyledButton onClick={this.sendMessage} size="lg">
                  <Translate id="contact.send" />
                  </StyledButton>
                  <Recaptcha
                    ref={ref => (this.recaptcha = ref)}
                    // local sitekey
                    sitekey="6Ld1b8EUAAAAAHIgTWfsoEnz19gJlACUs5J9tiaW"
                    // prod sitekey
                    // sitekey="6LcBQPIaAAAAAIYB2nGhgXKo8u6Z-7PB1pqXGkI3"
                    onResolved={this.onResolved}
                  />
                </Form>
              </Col>
            </Row>
          </Container>
        </Content>
      </FullPage>
    )
  }
}

const mapStateToProps = state => ({ state: state.languageReducer.projects })

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ addMessage }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(Contact))

const StyledLabel = styled.label`
  display: flex;
  margin-left: 10px;
  color: #ecf0a3;
  font-size: 18px;
`;

const StyledControl = styled(Form.Control)`
  width: 100%;
  background-color: ${props => props.theme.mdd};
  border: 0px;
  color: ${props => props.theme.ml};
  &:focus {
    background-color: ${props => props.theme.mdd};
    color: ${props => props.theme.ml};
    border: 0px;
    box-shadow: none;
  }
`
const StyledButton = styled(Button)`
  float: right;
  background-color: transparent;
  border: 2px solid ${props => props.theme.ml};
  border-radius: 5px;
  color: ${props => props.theme.ml};
`
