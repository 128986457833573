import React, { Component } from "react"
import styled, { withTheme } from "styled-components"
import background from "../images/Home.jpg"
import backgroundMobil from "../images/Home_mobil.jpg"

import FullPage from "../components/fullpage"
import Content from "../components/content"
import Title from "../components/title"
import { Translate } from "react-localize-redux"

class Header extends Component {
  render() {
    const theme = this.props.theme
    return (
      <FullPage
        id="header"
        backgroundColor={theme.gray}
        backgroundImage={background}
        backgroundImageMobil={backgroundMobil}
      >
        <Content verticalAlign="middle">
          <Title color={theme.ml}>
            <Translate id="header.hello" />
            <StyledSpan>Rigó Mátyás</StyledSpan>
          </Title>
          <Subtitle color={theme.ml}>
            <Translate id="header.fullStackDeveloper" />
          </Subtitle>
          <MoreInfo color={theme.ml}>
            <Translate id="header.moreInfo" />
            <span className="arrow">&#8594;</span>
          </MoreInfo>
        </Content>
      </FullPage>
    )
  }
}

export default withTheme(Header)

const StyledSpan = styled.span`
  color: ${props => props.theme.sd};
`

const Subtitle = styled.div`
  color: ${props => props.theme.ml};
  font-size: 2.5em;
  @media (max-width: 800px) {
    font-size: 1.5em;
  }
  font-weight: 700;
`

const MoreInfo = styled.div`

  display: inline;
  border: 4px solid ${props => props.theme.ml};
  border-radius: 30px;
  color: ${props => props.theme.ml};
  font-size: 1.5em;
  padding: 5px 15px;
  @media (max-width: 800px) {
    font-size: 1em;
  }
  font-weight: 700;
  .arrow {
    font-size: 2em;
    vertical-align: sub;
  }
`
