import React from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { Translate } from "react-localize-redux"
import { withLocalize } from "react-localize-redux"
import translations from "./translations.json"

const onMissingTranslation = ({ translationId }) => translationId

export const Local = withLocalize(
  class extends React.Component {
    constructor(props) {
      super(props)

      this.props.initialize({
        languages: [{ name: "English", code: "GB_ENG" }, { name: "Română", code: "RO" }],
        translation: translations,
        options: {
          renderToStaticMarkup,
          onMissingTranslation,
          renderInnerHtml: true,
        },
      })

      this.props.setActiveLanguage('RO');
    }

    render() {
      return null
    }
  },
)

export const loc = (value, props) => {
  return <Translate id={value} data={props} />
}

export const Loc = ({ value, ...data }) => {
  return loc(value, data)
}

export { Translate } from "react-localize-redux"
