import React from "react"
import { Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { withLocalize } from "react-localize-redux"
import { Translate } from "../localization"

import LanguageToggle from "./LanguageToggle"

const scroll = (id) => {
  const section = document.getElementById(id);
  if (section)
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

const navbar = () => {
  
  return (
    <StyledNavbar bg="dark" variant="dark" expand="md" fixed="top">
      <Navbar.Brand href="/" className="logo">
        <i className="fas fa-laptop-code" />
        {" Rigo Matyas"}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <StyledLink onClick={() => scroll("header")}>
          <Translate id="nav.home" />
        </StyledLink>
        <StyledLink onClick={() => scroll("about")}>
          <Translate id="nav.about" />
        </StyledLink>
        <StyledLink onClick={() => scroll("portfolio")}>
          <Translate id="nav.portfolio" />
        </StyledLink>
        <StyledLink onClick={() => scroll("contact")}>
          <Translate id="nav.contact" />
        </StyledLink>
        <LanguageToggle />
      </Navbar.Collapse>
    </StyledNavbar>
  )
}

export default withLocalize(navbar)

const StyledNavbar = styled(Navbar)`
  background: rgba(0, 0, 0, 0.6) !important;
  .logo {
    padding: 0rem 0rem;
    font-size: 1.5em;
    font-weight: 700;
  }
`

const StyledLink = styled.div`
  display: block;
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
`
