import styled from "styled-components";

const Title = styled.div`
  color: ${props => (props.color ? props.color : "#000")};
  font-size: 4em;
  @media (max-width: 800px) {
    font-size: 4em;
  }
  font-weight: 700;
`;

// TODO title_name-nek keress helyet

export default Title;
