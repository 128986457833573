import { todosRef, storage } from "../config/firebase"

export const getImage = () => async dispatch => {
  storage
    .ref("projects/folder.jpg")
    .getDownloadURL()
    .then(url => {
      dispatch({ type: "GET_IMAGE", imageUrl: url })
    })
}

// const getImageByName = imageName => {
//   storage
//     .ref("projects/" + imageName)
//     .getDownloadURL()
//     .then(url => {
//       return url
//     })
// }

export const addProject = project => async dispatch => {
  todosRef
    .push()
    .set(project)
    .then(val => {
      dispatch({
        type: "CREATE_PROJECT",
        payload: val,
      })
    })
}

export const fetchToDos = () => async dispatch => {
  todosRef.on("value", snapshot => {
    dispatch({
      type: "GET_PROJECTS",
      projects: snapshot.val(),
    })
  })
}

export const createProject = project => {
  return (dispatch, getState, { getFireBase, getFirestore }) => {
    const firestore = getFirestore()
    firestore
      .collection("projects")
      .add({ ...project })
      .then(() => {
        dispatch({ type: "CREATE_PROJECT", project })
      })
      .catch(err => {
        dispatch({ type: "CREATE_PROJECT_ERROR ", err })
      })
  }
}

export const createProjectWithImage = (project, image) => async dispatch => {
  console.log("project", project)
  const uploadImage = storage.ref(`projects/${project.imageName}`).put(image)
  uploadImage.on(
    "state_changed",
    snapshot => {
      console.log("image update in progress")
    },
    error => {
      console.log("error: ", error)
    },
    () => {
      storage
        .ref("projects")
        .child(project.imageName)
        .getDownloadURL()
        .then(url => {
          console.log("url: ", url)
          var newProject = { ...project, imageUrl: url }
          todosRef
            .push()
            .set(newProject)
            .then(() => {
              console.log("create project: ", newProject)
              dispatch({ type: "CREATE_PROJECT", newProject })
            })
        })
    },
  )
}

export const createProjectWithImage2 = project => {
  return dispatch => {
    const uploadImage = storage.ref(`projects/${project.imageName}`).put(project.image)
    uploadImage.on(
      "state_changed",
      snapshot => {
        console.log("image update in progress")
      },
      error => {
        console.log("error: ", error)
      },
      () => {
        storage
          .ref("projects")
          .child(project.imageName)
          .getDownloadURL()
          .then(url => {
            console.log("url: ", url)
          })
      },
    )
    todosRef
      .push()
      .set(project)
      .then(() => {
        dispatch({ type: "CREATE_PROJECT", project })
      })
  }
}

export const setShowDetails = (index, showDetails) => {
  return {
    type: "SET_SHOW_DETAILS",
    showDetails,
    index,
  }
}
