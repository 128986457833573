import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import styled, { withTheme } from "styled-components"

import FullPage from "../components/fullpage"
import Content from "../components/content"
import Title from "../components/title"

import {
  addProject,
  fetchToDos,
  getImage,
  createProjectWithImage,
  setShowDetails,
} from "../actions/projectAction"

import { Container, Row, Col, Button } from "react-bootstrap"
import { Translate } from "react-localize-redux"

class Portfolio extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAll: false
    }
  }

  componentWillMount() {
    this.props.fetchToDos()
    this.props.getImage()
  }

  render() {
    const theme = this.props.theme
    return (
      <FullPage id="portfolio" className="landing-text" backgroundColor={theme.mll}>
        <Content verticalAlign="middle">
          <Container style={{ paddingBottom: "60px" }}>
            <Title color={theme.md}>
              <Translate id="portfolio.title" />
            </Title>
            <Row style={{ marginTop: "50px", justifyContent: "center" }}>
              {this.props.projects ? this.loadProjectCards() : ""}
            </Row>
            {!this.state.showAll && 
              <ShowMoreButton className="btn-lg" onClick={() => { this.props.getImage(); this.setState({showAll: true}) }}>
                <Translate id="portfolio.showMore" />
              </ShowMoreButton>
            }
          </Container>
        </Content>
      </FullPage>
    )
  }

  loadProjectCards() {
    console.log("projects", this.props.projects)
    var size = this.state.showAll ? 100 : 3;
    var projects = this.props.projects.slice(0, 1 * size).map((project, i) => {
      return (
          <StyledCol id={i} key={i}  md={{ span: 3, offset: 1 }}>
            <StyledImg project={project} />
            <StyledTextBox>
              <StyledText>
                {project.title}
                <br /> <br />
                {project.year}
              </StyledText>
              <StyledButton onClick={() => window.open(project.site, '_blank', 'noopener,noreferrer') }>
                <Translate id="portfolio.moreInfo" />
                <span className="arrow">&#8594;</span>
              </StyledButton>
            </StyledTextBox>
          </StyledCol>
      )
    })
    return (
      <>
        <Row style={{ width: "100%" }}>{projects}</Row>
      </>
    )
  }
}

const StyledCol = styled(Col)`
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

const StyledImg = styled(Col)`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${props => (!props.project.showDetails ? props.project.imageUrl : "")});
  border-radius: 12px;
  box-sizing: content-box;
`

const StyledTextBox = styled(Col)`
  background-color: ${props => props.theme.md};
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 12px;
  padding-top: 2.5rem;
  &:hover {
    opacity: 1;
  }
`

const StyledText = styled.div`
  color: ${props => props.theme.mll};
  text-align: center;
  padding-bottom: 1.5rem;
  line-height: 1.125;
  font-weight: 600;
  font-size: 18px;
`

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 2px solid ${props => props.theme.sl};
  border-radius: 12px;
  color: ${props => props.theme.sl};
  padding: 0 1rem 0 1rem;
  &:hover {
    background-color: ${props => props.theme.sl};
    border: 2px solid ${props => props.theme.sl};
    color: ${props => props.theme.mll};
  }
`

const ShowMoreButton = styled(Button)`
  background-color: transparent;
  border: 2px solid ${props => props.theme.md};
  border-radius: 12px;
  color: ${props => props.theme.md};
  padding: 0 1rem 0 1rem;
  &:hover {
    background-color: ${props => props.theme.md};
    border: 2px solid ${props => props.theme.md};
    color: ${props => props.theme.mll};
  }
  font-size: 33px;
  font-weight: bold;
`

const mapStateToProps = state => ({ projects: state.project.projects })

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { addProject, fetchToDos, getImage, createProjectWithImage, setShowDetails },
    dispatch,
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(Portfolio))
