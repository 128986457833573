import React, { Component, Fragment } from "react"
import { Container, Row, Col, Button, Image } from "react-bootstrap"
import styled, { withTheme } from "styled-components"
import official1 from "../images/ue.jpg"
import madr from "../images/madr.png"
import official3 from "../images/official3.jpg"
import official4 from "../images/official4.jpg"
import official5 from "../images/official5.jpg"
import official6 from "../images/official6.jpg"
import pndr from "../images/PNDR.jpg"

class Test extends Component {
  render() {
    return (
      <div style={{backgroundColor: '#FFF'}}>
        <Container style={{paddingTop: 75, paddingBottom: 20}}>
          <StyledRow style={{display: "grid", gridTemplateColumns: "0.9fr 1.8fr 1fr 1fr 1fr 1.5fr", height: "min-content", alignItems: "center"}}>
            <Col>
                <StyledImage src={official1} fluid  />
            </Col>
            <Col>
              <StyledImage src={madr} fluid />
            </Col>
            <Col>
              <StyledImage src={pndr} fluid />
            </Col>
            <Col>
                <StyledImage src={official4} fluid style={{height: "4vw"}} />
            </Col>
            <Col>
                <StyledImage src={official5} fluid />
            </Col>
            <Col>
                <StyledImage src={official6} fluid />
            </Col>
          </StyledRow>
          <StyledRow style={{marginTop: 15}}>
            Proiect finanţat cu fonduri europene nerambursabile prin <span style={{fontWeight: "bold"}}>&nbsp;PROGRAMUL NAŢIONAL DE DEZVOLTARE RURALĂ (PNDR) și Strategia de Dezvoltare Locală&nbsp;</span> a teritoriului Grup de Acțiune Locală Homorod-Kukullo LEADER, <span style={{fontWeight: "bold"}}>&nbsp;MĂSURA 6/6A, 6B  - „START-UP în domeniul non-agricol”&nbsp;</span>
          </StyledRow>
          <StyledRow style={{marginTop: 15}}>
            Programul Naţional de Dezvoltare Rurală este implementat de <span style={{fontWeight: "bold"}}>&nbsp;AGENȚIA PENTRU FINANȚAREA INVESTIȚIILOR RURALE</span>, din subordinea <span style={{fontWeight: "bold"}}>&nbsp;MINISTERULUI AGRICULTURII ȘI DEZVOLTĂRII RURALE.&nbsp;</span> 
          </StyledRow>
          <StyledRow style={{marginTop: 15}}>
          PNDR este finanţat de &nbsp;<span style={{fontWeight: "bold"}}>UNIUNEA EUROPEANĂ</span>&nbsp; şi <span style={{fontWeight: "bold"}}>&nbsp;GUVERNUL ROMÂNIEI </span> &nbsp;prin <span style={{fontWeight: "bold"}}>&nbsp;FONDUL EUROPEAN AGRICOL PENTRU DEZVOLTARE RURALĂ.</span> 
          </StyledRow>
        </Container>
      </div>
    )
  }
}

const StyledRowTitle = styled(Row)`
  font-weight: bold;
  font-style: italic;
  font-size: 1em;
  text-align: center;
  width: 100%;
  justify-content: center;
  color: #848484;
`

const StyledRow = styled(Row)`
  text-align: center;
  width: 100%;
  justify-content: center;
  color: #848484;
  font-size: 0.8em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
`


const StyledLink = styled.a`
  color: #ff2a13;
  margin: 0px 5px;
`

const StyledImage = styled(Image)`
`

export default (withTheme(Test))