import React from "react"
import { withLocalize } from "react-localize-redux"
import styled from "styled-components"
import { Translate } from "../localization"

import { NavDropdown } from "react-bootstrap"

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => {
  return (
    <>
      <SNavDropdown
        title={<Translate id="nav.language" />}
        id="basic-nav-dropdown"
        style={{ color: "#FFF" }}
      >
        {languages.map(lang => (
          <NavDropdown.Item key={lang.code} onClick={() => setActiveLanguage(lang.code)}>
            {lang.name}
          </NavDropdown.Item>
        ))}
      </SNavDropdown>
    </>
  )
}

const SNavDropdown = styled(NavDropdown)`
  .nav-link {
    color: #fff;
    font-family: Montserrat;
  }
  .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.6);
    margin-top: 7px;
    border: 0;
  }
  .dropdown-item {
    color: #ffffff;
    font-family: Montserrat;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #333333;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

export default withLocalize(LanguageToggle)
