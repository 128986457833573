import React, { Component, Fragment } from "react"

import Navbar from "../components/navbar"
import Header from "./Header"
import About from "./About"
import Portfolio from "./Portfolio"
import Contact from "./Contact"
import Test from "./Test"
import MyMap from "./MyMap"

export default class Home extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <Test />
        <Header />
        <About />
        <Portfolio />
        <Contact />
        {/* <MyMap /> */}
      </Fragment>
    )
  }
}
