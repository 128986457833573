import styled from "styled-components";

const Content = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: ${props => (props.verticalAlign ? props.verticalAlign : "auto")};
  width: 100%;
`;

export default Content;
