const inicialState = {
  projects: [],
}

export default (state = inicialState, action) => {
  switch (action.type) {
    case "CREATE_PROJECT":
      break
    case "CREATE_PROJECT_ERROR":
      break
    case "GET_PROJECTS":
      if (action.projects == null){
        state = { ...state, projects: [] }
      } else {
        state = { ...state, projects: Object.values(action.projects) }
      }
      break
    case "GET_PROJECTS_ERROR":
      break
    case "GET_IMAGE_BY_NAME":
      break
    case "SET_SHOW_DETAILS":
      state = {
        ...state,
        projects: state.projects.map((project, i) =>
          i + "" === action.index ? { ...project, showDetails: action.showDetails } : project,
        ),
      }
      break
    default:
      break
  }
  return state
}
