import { combineReducers } from "redux"
import { firestoreReducer } from "redux-firestore"
import { localizeReducer } from "react-localize-redux"
import languageReducer from "./languageReducer"
import project from "./projectReducer"

export default combineReducers({
  languageReducer,
  firestore: firestoreReducer,
  localize: localizeReducer,
  project: project,
})
