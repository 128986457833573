import { messagesRef } from "../config/firebase"

export const addMessage = message => async dispatch => {
  messagesRef
    .push()
    .set(message)
    .then(val => {
      dispatch({
        type: "CREATE_CONTACT",
        payload: val,
      })
    })
}
