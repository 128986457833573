import styled from "styled-components"

const FullPage = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#FFF")};
  background-image: url(${props => (props.backgroundImage ? props.backgroundImage : "")});
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
  @media (max-width: 600px) {
    background-image: url(${props =>
      props.backgroundImageMobil ? props.backgroundImageMobil : ""});
  }
`

export default FullPage
