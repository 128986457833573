import firebase from "firebase/app"
import "firebase/database"
import "firebase/storage"

import { FirebaseConfig } from "../config/keys"

firebase.initializeApp(FirebaseConfig)

const databaseRef = firebase.database().ref()

export const todosRef = databaseRef.child("Projects")

export const messagesRef = databaseRef.child("message")

export const storage = firebase.storage()
