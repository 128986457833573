import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
//import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { Provider } from "react-redux"
import configureStore from "./store"
import { ThemeProvider } from "styled-components"
import { LocalizeProvider } from "react-localize-redux"

import { Local } from "./localization"

import "./App.css"
import Home from "./views/Home"
import Test from "./views/Test"
color: ;
const theme = {
  md: "#164057", // main dark
  // ml: "#F0F23A", // main light
  ml: "#ecf0a3",
  // sd: "#ED6F46", // second dark
  sd: "#ed6f46",
  sl: "#61AD9F", // second light
  white: "#FFF", // white
  mdd: "#0d2734",
  mll: "#F5F5F5",
  gray: "#464646",
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizeProvider store={configureStore()}>
          <Local />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/test" component={Test} />
          </Switch>
        </LocalizeProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
)
