import React, { Component } from "react"
import styled, { withTheme } from "styled-components"

import FullPage from "../components/fullpage"
import Content from "../components/content"
import Title from "../components/title"

import myselfImage from "../images/myself.jpg"

import { Container, Row, Col, Image } from "react-bootstrap"
import { Translate } from "react-localize-redux"

class About extends Component {
  render() {
    const theme = this.props.theme
    return (
      <FullPage id="about" className="landing-text" backgroundColor="#FFF">
        <Content verticalAlign="middle">
          <Container style={{ paddingBottom: "60px" }}>
            <Title color={theme.md}>
              <Translate id="about.title" />
            </Title>
            <Row style={{ marginTop: "50px" }}>
              <Col sm={4}>
                <StyledImage src={myselfImage} fluid />
              </Col>
              <Col sm={8}>
                <Subtitle>
                  <Translate id="about.introTitle" />
                </Subtitle>
                <Text>
                  <Translate id="about.introText" />
                </Text>
              </Col>
            </Row>
          </Container>
        </Content>
      </FullPage>
    )
  }
}

export default withTheme(About)

const StyledImage = styled(Image)`
`

const Subtitle = styled.div`
  color: ${props => props.theme.sl};
  font-size: 2em;
  margin-bottom: 40px;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 1.5em;
  }
  font-weight: bold;
`

const Text = styled.div`
  color: ${props => props.theme.sl};
  font-size: large;
  text-align: left;
`
