import React, { Component } from "react"
import styled, { withTheme } from "styled-components"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import FullPage from "../components/fullpage"
import Content from "../components/content"
import Title from "../components/title"

import { Container, Row, Col, Form, Button } from "react-bootstrap"

import { addMessage } from "../actions/contactAction"
import { getTranslate, Translate } from "react-localize-redux"
import { loc } from "../localization"
// import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet/lib"

class MyMap extends Component {

  render() {
    const theme = this.props.theme

    return (
      <FullPage id="contact" className="landing-text" backgroundColor={theme.md}>
        <Content verticalAlign="middle">
          <Container style={{ paddingBottom: "60px" }}>
            <Row>
              <Col>
                <Title color={theme.ml}>
                  <Translate id="contact.title" />
                </Title>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <Col>
                {/* <LeafletMap center={[45.9442858, 25.0094303]} zoom={6}>
                  <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker key={"123"} position={[45.9442858, 25.0094303]} />
                </LeafletMap> */}
                {/* <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[51.505, -0.09]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                </MapContainer> */}
              </Col>
            </Row>
          </Container>
        </Content>
      </FullPage>
    )
  }
}

const mapStateToProps = state => ({ state: state.languageReducer.projects })

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ addMessage }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(MyMap))